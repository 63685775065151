import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authenticatelogin,globalLoderAction } from "../Redux/Action";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useDispatch } from "react-redux";
function Login() {
  const dispatch = useDispatch();
  const [passworderror, setPassworderror] = useState("");
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handlechange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password.length > 3) {
      dispatch(authenticatelogin(user))
        .then((res) => {
          setLoad(true);
          if (res.data.data === "Wrong Email/Password. Try Again!") {
            setPassworderror(res.data.data);
          } else {
            navigate("/");
            window.location.reload();
          }
        })
        .catch((err) => {
          setPassworderror(err.response.data.error.message);
        });
    } else {
      setPassworderror("password must be  greater than 3 letters");
    }
  };
  return (
    <>
      <div class="user-area pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="user-all-form">
                <div class="contact-form">
                  <h3 class="user-title">Login</h3>
                  <form id="contactForm" onSubmit={handleSubmit}>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <input
                            type="text"
                            name="email"
                            onChange={handlechange}
                            value={user.email}
                            required
                            class="form-control"
                            data-error="Email Address*"
                            placeholder="Email Address*"
                          />
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form-group" style={{ display: "flex" }}>
                          <input
                            type={passwordVisible ? "text" : "password"}
                            required
                            name="password"
                            value={user.password}
                            onChange={handlechange}
                            className="form-control"
                            placeholder="Enter Password*"
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderRadius: "none",
                              background: "none",
                              marginLeft: "-50px",
                              border: "none",
                              fontSize: "20px",
                            }}
                            onClick={togglePasswordVisibility}
                          >
                            {passwordVisible ? (
                              <BsEyeSlash style={{ color: "#F290BB" }} />
                            ) : (
                              <BsEye style={{ color: "#F290BB" }} />
                            )}
                          </button>
                        </div>
                      </div>
                      <span style={{ color: "brown", marginLeft: "20px" }}>
                        {passworderror}
                      </span>
                      <div class="col-lg-12 form-condition">
                        <div class="agree-label">
                          <label for="chb1">
                            <a class="forget" href="/forgotpassword">
                              Forgot Password?
                            </a>
                          </label>
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12">
                        <button
                          type="submit"
                          class="default-btn border-radius-5"
                        >
                          Login Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="user-all-form p-5">
                <div class="contact-form">
                  <h3 class="user-title">New Customer?</h3>
                  <form id="contactForm">
                    <div class="row">
                      <div class="col-lg-12">
                        <h5>Create an Account</h5>
                      </div>

                      <div class="col-12">
                        <p>
                          Sign up for a free account at our store. Registration
                          is quick and easy. It allows you to be able to order
                          from our shop. To start shopping click register.
                        </p>
                      </div>

                      <div class="col-lg-12 col-md-12">
                        <Link to="/register">
                          <button class="default-btn border-radius-5">
                            Register Now
                          </button>
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
