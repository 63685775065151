function Loader() {
    return (
      <div style={{ width: "100%", height: "100vh",display:"flex",justifyContent:"center",alignItems:"center" ,position:"fixed",top:0,left:0,zIndex:9999,background:"white",}}>
        <div class="wrapper">
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="shadow"></div>
          <div class="shadow"></div>
          <div class="shadow"></div>
        </div>
      </div>
    );
    
}
export default Loader;