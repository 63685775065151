import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { globalLoderAction } from "../../Redux/Action";
import { BsCaretRightFill } from "react-icons/bs";
import blog1 from "../../assets/image/blog2.jpg";

function Blog2Details() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <>
        <div class="blog-details-area pt-100 pb-70">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="blog-details-content">
                  <div class="blog-preview-img">
                    <img src={blog1} alt="Blog Details" />
                  </div>
                  <p style={{textAlign:"justify"}}>
                    Have you heard the famous line that says, "It's our choices
                    that show what we truly are, far more than our abilities"?
                    If you're a Potterhead, you likely have. This statement,
                    simple yet profound, underscores the importance of our
                    decisions in shaping our future. Every choice we make in the
                    present has the potential to influence our lives in
                    significant ways.
                  </p>
                  <p style={{textAlign:"justify"}}>
                    At Beauty Tales, we believe that starting with a
                    healthy mind is crucial, and it all begins with the habit of
                    eating right. Why is good and nutritious food so pivotal to
                    a healthy lifestyle?
                  </p>
                  <ul>
                    <li style={{textAlign:"justify"}}>
                      Nutritious Food for Active Living: It keeps you active and
                      healthy, fostering a body free from suffering and
                      disease—a sanctuary for a healthy mind, filled with peace
                      and happiness.
                    </li>
                    <li style={{textAlign:"justify"}}>
                      Enhanced Immune Health and Metabolism: Fresh, pure, and
                      nutritious food boosts immune health and metabolism,
                      supporting better physical and mental well-being.
                    </li>
                    <li style={{textAlign:"justify"}}>
                      Healthy Physique and Glowing Skin: Good health shines
                      through in your appearance, promoting healthy, glowing
                      skin and strong, beautiful hair.
                    </li>
                    <li style={{textAlign:"justify"}}>
                      Ayurvedic Wisdom: According to Ayurveda, food can be
                      categorized into Satvik (fresh fruits and local
                      vegetables), Rajasik (spicy and deep-fried food), and
                      Tamasik (processed food). Choosing Satvik foods helps
                      maintain vitality and ward off ailments.
                    </li>
                  </ul>
                  <p style={{textAlign:"justify"}}>
                    In a world full of tempting but harmful foods, it's crucial
                    to realize the truth about nutrition. Processed and
                    deep-fried foods, often termed as 'Junk food,' can lead to
                    digestive issues, skin problems, and affect overall organ
                    health. Choosing a Satvik meal routine, filled with freshly
                    prepared, less spicy foods, and plenty of fresh fruits and
                    vegetables, is key to maintaining vitality and keeping
                    diseases at bay.
                  </p>

                  <blockquote class="blockquote">
                    <p style={{textAlign:"justify"}}>
                      Hydration is equally vital—water flushes toxins from your
                      body, contributing to radiant skin and overall wellness.
                      Remember, eating well isn't just about a meal; it's about
                      living well. Your choices shape your destiny. Choose good,
                      live good with Beauty Tales.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
export default Blog2Details;
