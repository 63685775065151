import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Error404 from "../Error/Error404";
import FAQ from "../Info/FAQ";
import Privacypolicy from "../Info/PrivacyPolicy";
import Termsnconditions from "../Info/Terms&Conditions";
import About from "../Pages/About";
import Cart from "../Pages/Cart";
import Categories from "../Pages/Categories";
import Checkout from "../Pages/CheckOut";
import Contact from "../Pages/Contact";
import Home from "../Pages/Home";
import Productdetails from "../Pages/Productdetail";
import Shop from "../Pages/Shop";
import Whishlist from "../Pages/Whishlist";
import Forgetpassword from "../User/ForgotPassword";
import Login from "../User/Login";
import Myaccount from "../User/MyAccount";
import Register from "../User/Register";
import Order from "../Pages/Order/Order";
import Getorderdetails from "../Pages/Getorderdetails";
import TrackingOrder from "../Pages/TrackingOrder";
import ReviewContainer from "../Pages/ReviewContainer";
import VerifyPassword from "../User/VerifyPassword";
import RefundandReturn from "../Info/Refund";
import ListReviews from "../Pages/ListReview";
import AddressForm from "../User/Address";
import GetAddress from "../User/GetAddress";
import UpdateAddress from "../User/UpdateAddress";
import UpdateProfile from "../User/UpdateProfile";
import Blogs from "../Pages/Blogs/Blogs";
import Blog1Details from "../Pages/Blogs/Blog1Details";
import Blog2Details from "../Pages/Blogs/Blog2Details";
import Blog3Details from "../Pages/Blogs/Blog3Detail";
import CategoryShop from "../Pages/CategoryShop";
import PaymentStatus from "../Pages/PaymentStatus";
import { useSelector } from "react-redux";
import BillingAddress from "../User/BillingAddress";
import ShippingandDelivery from "../Info/Shipping&Delivery";
import Loader from "../Common/Loader";

const Mainroutes = () => {
  //   const Token = localStorage.getItem("Authtok");

  const loader = useSelector((store) => store.globalLoder?.payload);

  return (
    <div>
      <Router>
        {loader && (
          // <div id="preloader">
          //   <div id="preloader-area">
          //     <div class="circle" id="circle_four"></div>
          //     <div class="circle" id="circle_three"></div>
          //     <div class="circle" id="circle_two"></div>
          //     <div class="circle" id="circle_one"></div>
          //   </div>
          //   <div class="preloader-section preloader-left"></div>
          //   <div class="preloader-section preloader-right"></div>
          // </div>
          <Loader/>
        )
        }
        
        <Header />

        <Routes>
          <Route exact path={"/faq"} element={<FAQ />} />
          <Route
            exact
            path={"/privacypolicy"}
            element={<Privacypolicy />}
          />{" "}
          <Route exact path={"/refund"} element={<RefundandReturn />} />{" "}
          <Route exact path={"/shipping"} element={<ShippingandDelivery />} />{" "}

          <Route
            exact
            path={"/termsandconditions"}
            element={<Termsnconditions />}
          />{" "}
          <Route exact path={"/about"} element={<About />} />{" "}
          <Route path="/paymentstatus" element={<PaymentStatus />} />
          <Route exact path={"/blogs"} element={<Blogs />} />{" "}
          <Route exact path={"/blogs_one"} element={<Blog1Details />} />{" "}
          <Route exact path={"/blogs_two"} element={<Blog2Details />} />{" "}
          <Route exact path={"/blogs_three"} element={<Blog3Details />} />{" "}
          <Route exact path={"/cart"} element={<Cart />} />{" "}
          <Route exact path={"/mywhishlist"} element={<Whishlist />} />{" "}
          <Route exact path={"/categories"} element={<Categories />} />{" "}
          <Route
            exact
            path={"/categoryshop/:categoryId"}
            element={<CategoryShop />}
          />
          <Route exact path={"/checkout"} element={<Checkout />} />{" "}
          <Route exact path={"/contact"} element={<Contact />} />{" "}
          <Route exact path={"/"} element={<Home />} />
          <Route
            exact
            path={"/productdetails/:id"}
            element={<Productdetails />}
          />
          <Route exact path={"/shop"} element={<Shop />} />{" "}
          <Route exact path={"/order"} element={<Order />} />{" "}
          <Route exact path={"/addressform"} element={<AddressForm />} />{" "}
          <Route exact path={"/billingaddress"} element={<BillingAddress />} />{" "}
          <Route exact path={"/address"} element={<GetAddress />} />{" "}
          <Route
            exact
            path={"/updateaddress/:id"}
            element={<UpdateAddress />}
          />{" "}
          <Route
            exact
            path={"/updateprofile/:id"}
            element={<UpdateProfile />}
          />{" "}
          <Route exact path={"/listreview"} element={<ListReviews />} />{" "}
          <Route
            exact
            path={"/orderdetail/:id"}
            element={<Getorderdetails />}
          />{" "}
          <Route exact path={"/addreview"} element={<ReviewContainer />} />{" "}
          {/* <Route exact path={"/reviewmodal"} element={<ReviewModal />} />{" "} */}
          <Route exact path={"/wishlist"} element={<Whishlist />} />{" "}
          <Route exact path={"/login"} element={<Login />} />
          <Route exact path={"/register"} element={<Register />} />{" "}
          <Route exact path={"/profile"} element={<Myaccount />} />{" "}
          <Route exact path={"/forgotpassword"} element={<Forgetpassword />} />{" "}
          <Route
            exact
            path={"/verifypassword/:id"}
            element={<VerifyPassword />}
          />{" "}
          <Route path={"*"} element={<Error404 />} />
          {/* <Route exact path={"*"} element={<SomethingWentWrong />} /> */}
        </Routes>
        <Footer />
        {/* </Suspense> */}
      </Router>
    </div>
  );
};

export default Mainroutes;
